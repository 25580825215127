import {createReducer} from "@reduxjs/toolkit";

const initialState = {
    detalleSubasta: {},
    showDeleteButton: false,
    deshabilitarInput: false,
    mostrarCargaOferta: true,
    mejorOferta: false
};

const detalleSubastaReducer = createReducer(initialState, (builder) => {
    builder.addCase("detalleSubasta/getSubasta", (state, action) => {
        state.detalleSubasta = action.payload;
    });

    builder.addCase("detalleSubasta/showDeleteButton", (state, action) => {
        state.showDeleteButton = action.payload;
    });

    builder.addCase("detalleSubasta/mostrarCargaOferta", (state, action) => {
        state.mostrarCargaOferta = action.payload;
    });

    builder.addCase("detalleSubasta/modificarSubasta", (state, action) => {
        state.detalleSubasta[action.payload.key] = action.payload.value;
    });

    builder.addCase("detalleSubasta/deshabilitarInputComitente", (state, action) => {
        state.deshabilitarInput = action.payload;
    });

    builder.addCase("detalleSubasta/reiniciarEstado", (state, action) => {
        return initialState;
    });

    builder.addCase("detalleSubasta/mejorOferta", (state, action) => {
        state.mejorOferta = true;
    });
});

export default detalleSubastaReducer;