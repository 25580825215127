import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { Menu, Switch, Spin } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { rutasBloqueadasPerfilCaja, rutasBloqueadasAdminBackoffice, rutasBloqueadasOperador, rutasBloqueadasDifusionCPD, rutasBloqueadasDifusionSD, rutasBloqueadasConsultaSD, rutasBloqueadasComitenteDMA, rutasBloqueadasConsultaDMA, rutasBloqueadasCtaExcl, rutasBloqueadasDifusionSGR, rutasBloqueadasOpMonitor, rutasBloqueadasWebServices, rutasBloqueadasConsultaCendeu } from '../../routes/permisos';
import './index.css';

const { SubMenu } = Menu;

const MenuItems = ({ toggleCollapsed }) => {
  const {usuario} = useSelector((state) => state.usuarioReducer);
  const tipoUsuario = usuario.user_type.id_perfil;
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const {menuStyle} = useSelector((state) => state.uiReducer);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [estados, setEstados] = useState({});
  const [rutasDelPerfil, setRutasDelPerfil] = useState([]);
  
  const mainPathSplit = path.split('/');
  const [openKeys, setOpenKeys] = useState([`${mainPathSplit.length >= 2 ? mainPathSplit[1] : 'dashboard'}`]);

  const checkEstados = useMemo(() => {
    return () => {
      let rutas;

    switch (tipoUsuario) {
      case 35: // caja
      rutas = rutasBloqueadasPerfilCaja;
      break;
  
      case 2: // backoffice
      rutas = rutasBloqueadasAdminBackoffice;
      break;
  
      case 3: // operador
      rutas = rutasBloqueadasOperador;
      break;
  
      case 4: // cta. excl.
      rutas = rutasBloqueadasCtaExcl;
      break;
  
      case 31: // difusionSGR
      rutas = rutasBloqueadasDifusionSGR;
      break;
  
      case 10: // difusionSD
      rutas = rutasBloqueadasDifusionSD;
      break;
  
      case 12: // consultaSD
      rutas = rutasBloqueadasConsultaSD;
      break;
  
      case 13: // operador monitor
      rutas = rutasBloqueadasOpMonitor;
      break;
  
      case 32: // difusionCPD
      rutas = rutasBloqueadasDifusionCPD;
      break;
  
      case 14: // comitenteDMA
      rutas = rutasBloqueadasComitenteDMA;
      break;
  
      case 5: // consultaDMA
      rutas = rutasBloqueadasConsultaDMA;
      break;
  
      case 89: // webServices/REST
      rutas = rutasBloqueadasWebServices;
      break;
  
      case 33: // consultaCendeu
      rutas = rutasBloqueadasConsultaCendeu;
      break;
  
      default:
          break;
    };

    setEstados({
      internos: rutas.find(ruta => ruta.includes("/internos")),
      usuarios: rutas.find(ruta => ruta.includes("/usuarios")),
      comitentes: rutas.find(ruta => ruta.includes("/comitentes")),
      cheques: rutas.find(ruta => ruta.includes("/cheques")),
      lotes: rutas.find(ruta => ruta.includes("/lotes")),
      subastas: rutas.find(ruta => ruta.includes("/subastas")),
      operados: rutas.find(ruta => ruta.includes("/operados")),
      ayuda: rutas.find(ruta => ruta.includes("/ayuda")),
      informes: rutas.find(ruta => ruta.includes("/informes")),
      cotizaciones: rutas.find(ruta => ruta.includes("/cotizaciones")),
      consultas: rutas.find(ruta => ruta.includes("/consultas")),
      tenencia: rutas.find(ruta => ruta.includes("/tenencia")),
      nominaAgentes: rutas.find(ruta => ruta.includes("/nominaDeAgentes")),
      is_staff: usuario.is_staff,
      admin: usuario.admin,
      admin_cupos: usuario.admin_cupos,
      admin_dma: usuario.admin_dma
    });
    setRutasDelPerfil(rutas);
    }
  });

  const onOpenChange = keys => {
    setOpenKeys(keys.length ? [keys[keys.length - 1]] : [keys])
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const onChange = async () => {
    await dispatch({type: "uiReducer/cambiarEstiloMenu"});
  }

  useEffect(() => {
    mainPathSplit.shift();
    setSelectedKeys([`${mainPathSplit.join('-')}`]);
  }, [path]);

  useEffect(() => {
    checkEstados();
  }, []);

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={menuStyle ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={[`${mainPathSplit.length >= 2 ? mainPathSplit[1] : 'dashboard'}`]}
      defaultOpenKeys={[`${mainPathSplit.length >= 2 ? mainPathSplit[1] : 'dashboard'}`]}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      // openKeys abre el submenu y selectedKeys abre el menu item
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      style={{width: "100%"}}
    >
      {!menuStyle && <Menu.Item key="switchClose" style={{display: "flex", alignItems: "center"}}>
      <Switch size="small" onChange={onChange}/>
      </Menu.Item>}

      {rutasDelPerfil.find(ruta => ruta === '/dashboard') && <Menu.Item key={"dashboard"} icon={<FeatherIcon icon="home" style={menuStyle ? {color: "#ADB4D2"} : {color: "#5A5F7D"}}/>} style={{display: "flex", alignItems: "center"}}>
        <NavLink onClick={toggleCollapsed} to={'/dashboard'}>
          {"Inicio"}
        </NavLink>
      </Menu.Item>}

      {estados.is_staff && estados.internos &&
        <SubMenu key="internos" icon={<FeatherIcon icon="hash" />} title="Internos">
          {rutasDelPerfil.find(ruta => ruta === '/internos/parametros') && <Menu.Item key={"internos-parametros"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/parametros'}>
            {"Parámetros"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/cheques') && <Menu.Item key={"internos-cheques"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/cheques'}>
            {"Cheques"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/garantias') && <Menu.Item key={"internos-garantias"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/garantias'}>
            {"Garantías"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/bcra') && <Menu.Item key={"internos-bcra"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/bcra'}>
            {"BCRA"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/archivos') && <Menu.Item key={"internos-archivos"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/archivos'}>
            {"Carga de Archivos"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/parametros/condiciones') && <Menu.Item key={"internos-parametros-condiciones"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/parametros/condiciones'}>
            {"Condic. PyME"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/internos/svs/certificantes') && <Menu.Item key={"internos-svs-certificantes"}>
          <NavLink onClick={toggleCollapsed} to={'/internos/svs/certificantes'}>
            {"Certific. SVS"}
          </NavLink>
          </Menu.Item>}
        </SubMenu>
      }

      {estados.usuarios &&
      <SubMenu key="usuarios" icon={<FeatherIcon icon="users" />} title="Usuarios">
          {rutasDelPerfil.find(ruta => ruta === '/usuarios/listado') && (estados.admin || estados.admin_cupos) && <Menu.Item key={"usuarios-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/listado'}>
            {"Listado de Usuarios"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/usuarios/certificados-pyme') && <Menu.Item key={"usuarios-certificados-pyme"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/certificados-pyme'}>
            {"CUIT PyME"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/usuarios/cuit-svs') && <Menu.Item key={"usuarios-cuit-svs"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/cuit-svs'}>
            {"CUIT SVS"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/usuarios/cartera-limitada') && estados.admin && <Menu.Item key={"usuarios-cartera-limitada"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/cartera-limitada'}>
            {"Cartera Limitada"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/usuarios/administracionCupos') && estados.admin_cupos && <Menu.Item key={"usuarios-administracionCupos"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/administracionCupos'}>
            {"Administración Cupos"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/usuarios/administracionDma') && estados.admin_dma && <Menu.Item key={"usuarios-administracionDma"}>
          <NavLink onClick={toggleCollapsed} to={'/usuarios/administracionDma'}>
            {"Administración DMA"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.comitentes && 
      <SubMenu key="comitentes" icon={<FeatherIcon icon="users"/>} title="Comitentes">
          {rutasDelPerfil.find(ruta => ruta === '/comitentes/listado') && <Menu.Item key={"comitentes-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/comitentes/listado'}>
            {"Listado de Comitentes"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/comitentes/garantiasComitente') && <Menu.Item key={"comitentes-garantiasComitente"}>
          <NavLink onClick={toggleCollapsed} to={'/comitentes/garantiasComitente'}>
            {"Movimiento de Garantías"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/comitentes/controlGarantias') && <Menu.Item key={"comitentes-controlGarantias"}>
          <NavLink onClick={toggleCollapsed} to={'/comitentes/controlGarantias'}>
            {"Control de Garantías"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.cheques && 
      <SubMenu key="cheques" icon={<FeatherIcon icon="credit-card" />} title="Instrumentos">
          {/* {rutasDelPerfil.find(ruta => ruta === '/cheques/nuevo') && <Menu.Item key={"cheques-nuevo"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/nuevo'}>
            {"Nuevo Cheque"}
          </NavLink>
          </Menu.Item>} */}

          {/* {rutasDelPerfil.find(ruta => ruta === '/cheques/listado') && <Menu.Item key={"cheques-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/listado'}>
            {"Listado de Cheques"}
          </NavLink>
          </Menu.Item>} */}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/instrumentos') && <Menu.Item key={"cheques-instrumentos"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/instrumentos'}>
            {"Instrumentos"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/autorizaciones') && <Menu.Item key={"cheques-autorizaciones"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/autorizaciones'}>
            {"Autorizaciones"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/remesas') && <Menu.Item key={"cheques-remesas"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/remesas'}>
            {"Listado de Remesas"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/cambioSegmento') && <Menu.Item key={"cheques-cambioSegmento"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/cambioSegmento'}>
            {"Cambio de Segmento"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/cambioMasivo') && <Menu.Item key={"cheques-cambioMasivo"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/cambioMasivo'}>
            {"Cambio Masivo a Gara"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/bloqueo') && estados.is_staff && <Menu.Item key={"cheques-bloqueo"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/bloqueo'}>
            {"Bloqueo CVSA"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/consulta') && <Menu.Item key={"cheques-consulta"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/consulta'}>
            {"Consulta"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cheques/pendientes') && <Menu.Item key={"cheques-pendientes"}>
          <NavLink onClick={toggleCollapsed} to={'/cheques/pendientes'}>
            {"Instrumentos Pendientes"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.lotes && 
      <SubMenu key="lotes" icon={<FeatherIcon icon="list" />} title="Lotes">
          {rutasDelPerfil.find(ruta => ruta === '/lotes/listado') && <Menu.Item key={"lotes-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/lotes/listado'}>
            {"Listado de Lotes"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.subastas && 
      <SubMenu key="subastas" icon={<FeatherIcon icon="list" />} title="Subastas">
          {rutasDelPerfil.find(ruta => ruta === '/subastas/listado') && <Menu.Item key={"subastas-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/subastas/listado'}>
            {"Listado"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/subastas/baja') && <Menu.Item key={"subastas-baja"}>
          <NavLink onClick={toggleCollapsed} to={'/subastas/baja'}>
            {"Baja de Operaciones"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/subastas/consulta-baja') && <Menu.Item key={"subastas-consulta-baja"}>
          <NavLink onClick={toggleCollapsed} to={'/subastas/consulta-baja'}>
            {"Consulta Baja de Operac."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/subastas/cambio-comitente') && <Menu.Item key={"subastas-cambio-comitente"}>
          <NavLink onClick={toggleCollapsed} to={'/subastas/cambio-comitente'}>
            {"Cambio de Comitente"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.operados && 
      <SubMenu key="operados" icon={<FeatherIcon icon="file-text" />} title="Operados">
          {rutasDelPerfil.find(ruta => ruta === '/operados/informeConcertacion') && <Menu.Item key={"operados-informeConcertacion"}>
          <NavLink onClick={toggleCollapsed} to={'/operados/informeConcertacion'}>
            {"Inf. de Concertación"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/operados/registroOperaciones') && <Menu.Item key={"operados-registroOperaciones"}>
          <NavLink onClick={toggleCollapsed} to={'/operados/registroOperaciones'}>
            {"Registro de Operac."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/operados/totales') && <Menu.Item key={"operados-totales"}>
          <NavLink onClick={toggleCollapsed} to={'/operados/totales'}>
            {"Totales Operados"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/operados/neto-comitente') && <Menu.Item key={"operados-neto-comitente"}>
          <NavLink onClick={toggleCollapsed} to={'/operados/neto-comitente'}>
            {"Neto por Comitente"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.ayuda && 
      <SubMenu key="ayuda" icon={<FeatherIcon icon="help-circle" />} title="Ayuda">
          {rutasDelPerfil.find(ruta => ruta === '/ayuda/listadoResponsables') && <Menu.Item key={"ayuda-listadoResponsables"}>
          <NavLink onClick={toggleCollapsed} to={'/ayuda/listadoResponsables'}>
            {"Listado de Resp."}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.informes && 
      <SubMenu key="informes" icon={<FeatherIcon icon="help-circle" />} title="Informes">
          {rutasDelPerfil.find(ruta => ruta === '/informes/consultaCendeu') && <Menu.Item key={"informes-consultaCendeu"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/consultaCendeu'}>
            {"Consulta CENDEU"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informes/librador') && <Menu.Item key={"informes-librador"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/librador'}>
            {"Inf. Librador-Gara."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informes/librador-noGara') && <Menu.Item key={"informes-librador-noGara"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/librador-noGara'}>
            {"Inf. Librador-No Gara."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informes/cupoGarantizado') && <Menu.Item key={"informes-cupoGarantizado"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/cupoGarantizado'}>
            {"Inf. Oper./Cupo Gara."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informes/generalGarantizado') && <Menu.Item key={"informes-generalGarantizado"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/generalGarantizado'}>
            {"Inf. General Gara."}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informes/consulta-instrumentos') && <Menu.Item key={"informes-consulta-instrumentos"}>
          <NavLink onClick={toggleCollapsed} to={'/informes/consulta-instrumentos'}>
            {"Consulta Instr."}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.cotizaciones && 
      <SubMenu key="cotizaciones" icon={<FeatherIcon icon="dollar-sign" />} title="Cotizaciones">
          {rutasDelPerfil.find(ruta => ruta === '/cotizaciones/listado') && <Menu.Item key={"cotizaciones-listado"}>
          <NavLink onClick={toggleCollapsed} to={'/cotizaciones/listado'}>
            {"Listado"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.consultas && 
      <SubMenu key="consultas" icon={<FeatherIcon icon="help-circle" />} title="Consultas">
          {rutasDelPerfil.find(ruta => ruta === '/consultas/bcra') && <Menu.Item key={"consultas-bcra"}>
          <NavLink onClick={toggleCollapsed} to={'/consultas/bcra'}>
            {"BCRA"}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {estados.tenencia && 
      <SubMenu key="depositantes" icon={<FeatherIcon icon="dollar-sign" />} title="Depositantes">
          {rutasDelPerfil.find(ruta => ruta === '/cuentaCorriente') && <Menu.Item key={"cuentaCorriente"}>
          <NavLink onClick={toggleCollapsed} to={'/cuentaCorriente'}>
            {"Consulta Cuenta Corriente"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/tenencia') && <Menu.Item key={"tenencia"}>
          <NavLink onClick={toggleCollapsed} to={'/tenencia'}>
            {"Tenencia de CPD"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/informeDepositos') && <Menu.Item key={"informeDepositos"}>
          <NavLink onClick={toggleCollapsed} to={'/informeDepositos'}>
            {"Informe de Depósitos"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/solicitudFondos') && <Menu.Item key={"solicitudFondos"}>
          <NavLink onClick={toggleCollapsed} to={'/solicitudFondos'}>
            {"Solicitud de Fondos"}
          </NavLink>
          </Menu.Item>}

          {rutasDelPerfil.find(ruta => ruta === '/cuentasBancariasHabilitadas') && <Menu.Item key={"cuentasBancariasHabilitadas"}>
          <NavLink onClick={toggleCollapsed} to={'/cuentasBancariasHabilitadas'}>
            {"Cuentas Bancarias Habilit."}
          </NavLink>
          </Menu.Item>}
      </SubMenu>
      }

      {rutasDelPerfil.find(ruta => ruta === '/mav-data') && <Menu.Item key={"mavData"}>
      <NavLink onClick={toggleCollapsed} to={'/mav-data'}>
        {"MAV-Data"}
      </NavLink>
      </Menu.Item>}

      <Menu.Item key="nominaDeAgentes"  icon={<FeatherIcon icon="users" style={menuStyle ? {color: "#ADB4D2"} : {color: "#5A5F7D"}}/>} style={{display: "flex", alignItems: "center"}}>
        <NavLink to={`/nominaDeAgentes`}>
          Nomina De Agentes
        </NavLink>
      </Menu.Item>

      {menuStyle && <Menu.Item key="switchOpen" icon={<Switch defaultChecked onChange={onChange} size="small"/>} style={{display: "flex", alignItems: "center"}}>
        <span>Colapsar menú</span>
      </Menu.Item>}
    </Menu>
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default memo(MenuItems);
