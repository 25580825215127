import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { errorGlobal } from '../utility/constants';
import eventBusService, {EXPIRED_SESSION_EVENT} from '../services/eventBusService';
import { MavRoute } from './components/logueado';
import { NotLoggedRoutes } from './components/deslogueado';

// components
const SignIn = lazy(() => import('../pages/signIn'));
const NotFound = lazy(() => import('../pages/notFound'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Internos = lazy(() => import('../pages/internos'));
const Clientes = lazy(() => import('../pages/internos/parametros/clientes'));
const TiposDeCliente = lazy(() => import('../pages/internos/parametros/tiposDeCliente'));
const CategoriasDeCNV = lazy(() => import('../pages/internos/parametros/categoriasDeCNV'));
const CategoriasDeIVA = lazy(() => import('../pages/internos/parametros/categoriasDeIVA'));
const Comitentes = lazy(() => import('../pages/comitentes'));
const Bancos = lazy(() => import('../pages/internos/parametros/bancos'));
const Instrumentos = lazy(() => import('../pages/internos/parametros/instrumentos'));
const Parametros = lazy(() => import('../pages/internos/parametros/parametros'));
const ParametrosCheque = lazy(() => import('../pages/internos/parametros/parametrosCheque'));
const Custodios = lazy(() => import('../pages/internos/parametros/custodios'));
const Feriados = lazy(() => import('../pages/internos/parametros/feriados'));
const Usuarios = lazy(() => import('../pages/usuarios'));
const TiposDeUsuario = lazy(() => import('../pages/internos/parametros/tiposDeUsuario'));
const Segmentos = lazy(() => import('../pages/internos/parametros/segmentos'));
const Monedas = lazy(() => import('../pages/internos/parametros/monedas'));
const Provincias = lazy(() => import ('../pages/internos/parametros/provincias'));
const Localidades = lazy(() => import('../pages/internos/parametros/localidades'));
const GarantiasComitente = lazy(() => import('../pages/garantiasComitente'));
const Plazos = lazy(() => import("../pages/internos/parametros/plazos"));
const ImportarCheques = lazy(() => import('../pages/internos/bcra/importarCheques'));
const ImportarDeudores = lazy(() => import('../pages/internos/bcra/importarDeudores'));
const ImportarPadron = lazy(() => import('../pages/internos/bcra/importarPadron'));
const AutorizacionPorRemesa = lazy(() => import('../pages/internos/cheques/autorizacionPorRemesa'));
const NuevoCheque = lazy(() => import('../pages/cheques/nuevo'));
const ListadoCheque = lazy(() => import('../pages/cheques/listado'));
const ListadoRemesas = lazy(() => import('../pages/cheques/remesas'));
const ListadoLotes = lazy(() => import('../pages/lotes/listado'));
const ListadoSubastas = lazy(() => import('../pages/subastas/listado'));
const DetalleSubasta = lazy(() => import('../pages/subastas/detalle'));
const ListadoCotizaciones = lazy(() => import('../pages/cotizaciones/listado'));
const ConsultaBCRA = lazy(() => import('../pages/consultas/bcra'));
const NominaDeAgentes = lazy(() => import('../pages/nominaDeAgentes'));
const ForgotPassword = lazy(() => import("../pages/constraseña/forgotPassword"));
const RecoveryPassword = lazy(() => import("../pages/constraseña/recoveryPassword"));
const NewPassword = lazy(() => import("../pages/usuarios/newPassword"));
const AutorizarGarantias = lazy(() => import("../pages/internos/comitentes/autorizacionGarantias"));
const ComitentePyme = lazy(() => import("../pages/comitentes/comitentePyme"));
const ControlGarantias = lazy(() => import("../pages/garantiasComitente/controlGarantias"));
const InformesGarantias = lazy(() => import("../pages/internos/comitentes/informesGarantias"));
const ActualizarCotizaciones = lazy(() => import("../pages/internos/comitentes/actualizacionCotizaciones"));
const InformeConcertacion = lazy(() => import("../pages/operados/informesConcertacion"));
const MavData = lazy(() => import("../pages/mav-data"));
const BajaSubasta = lazy(() => import("../pages/subastas/baja"));
const CertificanteSvs = lazy(() => import("../pages/internos/certificanteSVS"));
const CuitSvs = lazy(() => import("../pages/usuarios/cuitSvs"));
const RegistroOperaciones = lazy(() => import("../pages/operados/registroOperaciones"));
const ListadoResponsables = lazy(() => import("../pages/ayuda/listadoResponsables"));
const DiferencialCheques = lazy(() => import("../pages/internos/bcra/diferencialCheques"));
const CambioSegmento = lazy(() => import("../pages/cheques/cambioSegmento"));
const CambioMasivo = lazy(() => import("../pages/cheques/cambioSegmentoMasivo"));
const RescateCvsa = lazy(() => import("../pages/internos/cheques/rescateCvsa"));
const IngresoRescateCvsa = lazy(() => import("../pages/internos/cheques/ingresoRescateCvsa"));
const Cotizaciones = lazy(() => import("../pages/internos/parametros/cotizaciones"));
const CarteraLimitada = lazy(() => import("../pages/usuarios/carteraLimitada"));
const CertificadosMiPyme = lazy(() => import("../pages/usuarios/miPyme"));
const Condiciones = lazy(() => import("../pages/internos/parametros/condiciones"));
const InstrumentosCheques = lazy(() => import("../pages/cheques/instrumentos"));
const DetalleLote = lazy(() => import("../pages/lotes/detalle"));
const CuentaCorriente = lazy(() => import("../pages/cuentaCorriente"));
const ChequesPendientesCaja = lazy(() => import("../pages/cheques/pendientesUsuarioCaja"));
const ConsultaChequesCaja = lazy(() => import("../pages/cheques/consultaUsuarioCaja"));
const AdministracionCupos = lazy(() => import("../pages/usuarios/administracionCupos"));
const AdministracionDma = lazy(() => import("../pages/usuarios/administracionDMA"));
const Tenencia = lazy(() => import("../pages/tenencia"));
const InformeDepositos = lazy(() => import("../pages/informeDepositos"));
const SolicitudFondos = lazy(() => import("../pages/solicitudFondos"));
const CuentasBancariasHabilitadas = lazy(() => import("../pages/cuentasBancariasHabilitadas"));
const Sdib = lazy(() => import("../pages/internos/sdib"));
const Gic = lazy(() => import("../pages/internos/gic"));
const Bloqueo = lazy(() => import("../pages/cheques/bloqueo"));
const TotalesOperados = lazy(() => import("../pages/operados/operados"));
const Cendeu = lazy(() => import("../pages/informes/cendeu"));
const InformeLibrador = lazy(() => import("../pages/informes/librador"));
const NetoComitente = lazy(() => import("../pages/operados/netoComitente"));
const ConsultaBajas = lazy(() => import("../pages/subastas/consultaBajas"));
const NoGaraLibrador = lazy(() => import("../pages/informes/noGaraLibrador"));
const OperacionesCupoGarantizado = lazy(() => import("../pages/informes/operCupoGara"));
const GeneralGarantizado = lazy(() => import("../pages/informes/generalGara"));
const ComportamientoFce = lazy(() => import("../pages/internos/parametros/comportamientoFce"));
const VigenciaPass = lazy(() => import("../pages/internos/parametros/vigenciaPass"));
const AutorizacionesCheques = lazy(() => import("../pages/cheques/autorizaciones"));
const Inhibicion = lazy(() => import("../pages/internos/parametros/inhibicion"));
const CambioComitente = lazy(() => import("../pages/subastas/cambioComitente"));
const CambioComitenteDetalle = lazy(() => import("../pages/subastas/cambioComitente/cambioComitenteDetalle"));

const Routes = () => {
    const {loading} = useSelector((state) => state.autenticacionReducer);
    const [estado, setEstado] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if(estado) {
            eventBusService.on(EXPIRED_SESSION_EVENT, () => {
                dispatch({type: "autentication/desloguear"});
                errorGlobal("", "Tu sesión ha expirado.", "Vuelva a ingresar nuevamente.");
                setEstado(false);
            });
        }
    }, []);
    //if(loading === "pending") return <div className="spin"><Spin /></div>

    return (<>
            <Router basename="/">
                <Suspense fallback={<div className="spin"><Spin /></div>}>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to={loading === "fulfilled" ? "/dashboard" : "/signIn"} />} />
    
                        <NotLoggedRoutes loading={loading} path="/signIn" component={SignIn} exact />
    
                        <NotLoggedRoutes loading={loading} path="/password/forgot" component={ForgotPassword} exact />
    
                        <NotLoggedRoutes loading={loading} path="/password/change" component={NewPassword} exact />
    
                        <NotLoggedRoutes loading={loading} path={`/recovery/:uid/:token/`} component={RecoveryPassword} exact />
    
                        <MavRoute loading={loading} path="/dashboard" component={Dashboard} exact />
                        <MavRoute loading={loading} path="/password/change/logged" component={NewPassword} exact />
    
                        {/* internos-menu */}
                        <MavRoute loading={loading} path="/internos/parametros" component={Internos} exact />
                        <MavRoute loading={loading} path="/internos/cheques" component={Internos} exact />
                        <MavRoute loading={loading} path="/internos/garantias" component={Internos} exact />
                        <MavRoute loading={loading} path="/internos/bcra" component={Internos} exact />
                        <MavRoute loading={loading} path="/internos/archivos" component={Internos} exact />
    
                        {/* internos */}
                        <MavRoute loading={loading} path="/internos/parametros/clientes" component={Clientes} exact />
                        <MavRoute loading={loading} path="/internos/parametros/tiposDeCliente" component={TiposDeCliente} exact />
                        <MavRoute loading={loading} path="/internos/parametros/categoriasDeCNV" component={CategoriasDeCNV} exact />
                        <MavRoute loading={loading} path="/internos/parametros/categoriasDeIVA" component={CategoriasDeIVA} exact />
                        <MavRoute loading={loading} path="/internos/parametros/bancos" component={Bancos} exact />
                        <MavRoute loading={loading} path="/internos/parametros/instrumentos" component={Instrumentos} exact />
                        <MavRoute loading={loading} path="/internos/parametros/parametros" component={Parametros} exact />
                        <MavRoute loading={loading} path="/internos/parametros/parametrosCheque" component={ParametrosCheque} exact />
                        <MavRoute loading={loading} path="/internos/parametros/custodios" component={Custodios} exact />
                        <MavRoute loading={loading} path="/internos/parametros/feriados" component={Feriados} exact />
                        <MavRoute loading={loading} path="/internos/parametros/tiposDeUsuario" component={TiposDeUsuario} exact />
                        <MavRoute loading={loading} path="/internos/parametros/provincias" component={Provincias} exact />
                        <MavRoute loading={loading} path="/internos/parametros/localidades" component={Localidades} exact />
                        <MavRoute loading={loading} path="/internos/parametros/segmentos" component={Segmentos} exact />
                        <MavRoute loading={loading} path="/internos/parametros/monedas" component={Monedas} exact />
                        <MavRoute loading={loading} path="/internos/parametros/plazos" component={Plazos} exact />
                        <MavRoute loading={loading} path="/internos/parametros/cotizaciones" component={Cotizaciones} exact />
                        <MavRoute loading={loading} path="/internos/parametros/vigenciaPassword" component={VigenciaPass} exact />
                        <MavRoute loading={loading} path="/internos/parametros/inhibicion" component={Inhibicion} exact />
                        <MavRoute loading={loading} path="/internos/bcra/importarDeudores" component={ImportarDeudores} exact />
                        <MavRoute loading={loading} path="/internos/bcra/importarCheques" component={ImportarCheques} exact />
                        <MavRoute loading={loading} path="/internos/bcra/importarPadron" component={ImportarPadron} exact />
                        <MavRoute loading={loading} path="/internos/bcra/cheques" component={DiferencialCheques} exact />
                        <MavRoute loading={loading} path="/internos/cheques/autorizacionPorRemesa" component={AutorizacionPorRemesa} exact />
                        <MavRoute loading={loading} path="/internos/cheques/rescate" component={RescateCvsa} exact />
                        <MavRoute loading={loading} path="/internos/cheques/ingresoRescate" component={IngresoRescateCvsa} exact />
                        <MavRoute loading={loading} path="/internos/garantias/garantiasComitente" component={GarantiasComitente} exact />
                        <MavRoute loading={loading} path="/internos/garantias/autorizacionGarantias" component={AutorizarGarantias} exact />
                        <MavRoute loading={loading} path="/internos/garantias/informesGarantias" component={InformesGarantias} exact />
                        <MavRoute loading={loading} path="/internos/garantias/actualizarCotizaciones" component={ActualizarCotizaciones} exact />
                        <MavRoute loading={loading} path="/internos/svs/certificantes" component={CertificanteSvs} exact />
                        <MavRoute loading={loading} path="/internos/parametros/condiciones" component={Condiciones} exact />
                        <MavRoute loading={loading} path="/internos/archivos/sdib" component={Sdib} exact />
                        <MavRoute loading={loading} path="/internos/archivos/gic" component={Gic} exact />
                        <MavRoute loading={loading} path="/internos/bcra/fce" component={ComportamientoFce} exact />
    
                        {/* usuarios */}
                        <MavRoute loading={loading} path="/usuarios/listado" component={Usuarios} exact />
                        <MavRoute loading={loading} path="/usuarios/cuit-svs" component={CuitSvs} exact />
                        <MavRoute loading={loading} path="/usuarios/cartera-limitada" component={CarteraLimitada} exact />
                        <MavRoute loading={loading} path="/usuarios/certificados-pyme" component={CertificadosMiPyme} exact />
                        <MavRoute loading={loading} path="/usuarios/administracionCupos" component={AdministracionCupos} exact />
                        <MavRoute loading={loading} path="/usuarios/administracionDma" component={AdministracionDma} exact />
    
                        {/* comitentes */}
                        <MavRoute loading={loading} path="/comitentes/listado" component={Comitentes} exact />
                        <MavRoute loading={loading} path="/comitentes/garantiasComitente" component={GarantiasComitente} exact />
                        <MavRoute loading={loading} path="/comitentes/controlGarantias" component={ControlGarantias} exact />
                        <MavRoute loading={loading} path="/comitentes/cuitPyme" component={ComitentePyme} exact />
    
                        {/* cheques */}
                        <MavRoute loading={loading} path="/cheques/nuevo" component={NuevoCheque} exact />
                        <MavRoute loading={loading} path="/cheques/consulta/:id" component={NuevoCheque} exact />
                        <MavRoute loading={loading} path="/cheques/editar/:id" component={NuevoCheque} exact />
                        {/* <MavRoute loading={loading} path="/cheques/listado" component={ListadoCheque} exact /> */}
                        <MavRoute loading={loading} path="/cheques/remesas" component={ListadoRemesas} exact />
                        <MavRoute loading={loading} path="/cheques/cambioSegmento" component={CambioSegmento} exact />
                        <MavRoute loading={loading} path="/cheques/cambioMasivo" component={CambioMasivo} exact />
                        <MavRoute loading={loading} path="/cheques/instrumentos" component={InstrumentosCheques} exact />
                        <MavRoute loading={loading} path="/cheques/consulta" component={ConsultaChequesCaja} exact />
                        <MavRoute loading={loading} path="/cheques/pendientes" component={ChequesPendientesCaja} exact />
                        <MavRoute loading={loading} path="/cheques/autorizaciones" component={AutorizacionesCheques} exact />
                        <MavRoute loading={loading} path="/cheques/bloqueo" component={Bloqueo} exact />
    
                        {/* lotes */}
                        <MavRoute loading={loading} path="/lotes/listado" component={ListadoLotes} exact />
                        <MavRoute loading={loading} path="/lotes/detalle/:id" component={DetalleLote} exact />
    
                        {/* subastas */}
                        <MavRoute loading={loading} path="/subastas/listado" component={ListadoSubastas} exact />
                        <MavRoute loading={loading} path="/subastas/detalle/:id" component={DetalleSubasta} exact />
                        <MavRoute loading={loading} path="/subastas/baja" component={BajaSubasta} exact />
                        <MavRoute loading={loading} path="/subastas/consulta-baja" component={ConsultaBajas} exact />
                        <MavRoute loading={loading} path='/subastas/cambio-comitente' component={CambioComitente} exact />
                        <MavRoute loading={loading} path='/subastas/cambio-comitente/:id' component={CambioComitenteDetalle} exact />
    
                        {/* operados */}
                        <MavRoute loading={loading} path="/operados/informeConcertacion" component={InformeConcertacion} exact />
                        <MavRoute loading={loading} path="/operados/registroOperaciones" component={RegistroOperaciones} exact />
                        <MavRoute loading={loading} path="/operados/totales" component={TotalesOperados} exact />
                        <MavRoute loading={loading} path="/operados/neto-comitente" component={NetoComitente} exact />
    
                        {/* ayuda */}
                        <MavRoute loading={loading} path="/ayuda/listadoResponsables" component={ListadoResponsables} exact />
    
                        {/* informes */}
                        <MavRoute loading={loading} path="/informes/consultaCendeu" component={Cendeu} exact />
                        <MavRoute loading={loading} path="/informes/librador" component={InformeLibrador} exact />
                        <MavRoute loading={loading} path="/informes/librador-noGara" component={NoGaraLibrador} exact />
                        <MavRoute loading={loading} path="/informes/cupoGarantizado" component={OperacionesCupoGarantizado} exact />
                        <MavRoute loading={loading} path="/informes/generalGarantizado" component={GeneralGarantizado} exact />
    
                        {/* consultas */}
                        <MavRoute loading={loading} path="/consultas/bcra" component={ConsultaBCRA} exact />
    
                        {/* cotizaciones */}
                        <MavRoute loading={loading} path="/cotizaciones/listado" component={ListadoCotizaciones} exact />
    
                        {/* nomina de agentes */}
                        <MavRoute loading={loading} path="/nominaDeAgentes" component={NominaDeAgentes} exact />
    
                        {/* cuenta corriente */}
                        <MavRoute loading={loading} path="/cuentaCorriente" component={CuentaCorriente} exact />
                        <MavRoute loading={loading} path="/tenencia" component={Tenencia} exact />
                        <MavRoute loading={loading} path="/informeDepositos" component={InformeDepositos} exact />
                        <MavRoute loading={loading} path="/solicitudFondos" component={SolicitudFondos} exact />
                        <MavRoute loading={loading} path="/cuentasBancariasHabilitadas" component={CuentasBancariasHabilitadas} exact />
    
                        {/* mav-data */}
                        <MavRoute loading={loading} path="/mav-data" component={MavData} exact />
                        
    
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            </Router>
    </>);
}

export default Routes;
