import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

export const NotLoggedRoutes = ({loading, path, component}) => {
    console.log(loading);
    //if(loading === "preLogueado") return <h1>Cargando...</h1>;
    if(loading === "resetPass") return <ResetPass loading={loading} />;

    return loading === "fulfilled" ? <ValidarPermisosAlIngresar><Redirect to={"/dashboard"} /></ValidarPermisosAlIngresar> : <Route path={path} component={component} />;
};

const ResetPass = ({loading}) => {
    window.location.reload();
    return <Redirect to={'/password/change'} />;
}

const ValidarPermisosAlIngresar = ({children}) => {
    const {usuario} = useSelector((state) => state.usuarioReducer);

    if(usuario && (usuario.caduca === "Caduco" || usuario.resetear_password)) return <Redirect to={'/password/change/logged'} />
    // redirecciones al ingresar al sistema según el tipo de usuario
    switch (usuario.user_type.id_perfil) {
        case 35: // caja
        return <Redirect to={"/cheques/consulta"} />;

        case 31: // difusionSGR
        return <Redirect to={"/subastas/listado"} />;

        case 10: // difusionSD
        return <Redirect to={"/tenencia"} />;

        case 12: // consultaSD
        return <Redirect to={"/tenencia"} />;

        case 32: // difusionCPD
        return <Redirect to={"/cheques/instrumentos"} />;

        case 14: // comitenteDMA
        return <Redirect to={"/cheques/instrumentos"} />;

        case 89: // webServices/REST
        return <Redirect to={"/signIn"} />;

        case 33: // consultaCendeu
        return <Redirect to={"/informes/consultaCendeu"} />;

        default:
            break;
    };
    
    return children;
};