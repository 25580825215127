import {createReducer, current } from "@reduxjs/toolkit";

const initialState = {
    comitentes: [],
    crearComitente: 0,
    comitenteNoValido: false,
    cheques: [],
    repetirComitente: [],
    chequesConComitentes: [],
    comitenteElegidoEnCondomino: {}
};

const comitenteSubasta = createReducer(initialState, (builder) => {
    builder.addCase("comitenteSubasta/guardarComitentes", (state, action) => {
        state.comitentes = action.payload;
        const datosComitente = action.payload.response.find(cheque => {
            if(cheque.cheque === action.payload.row.id) return cheque
        });

        const agregoComitente = state.cheques.map(cheque => {
            if(cheque.id === action.payload.row.id) {
                if(datosComitente) {
                    cheque.comitente = datosComitente.comitente_comprador;
                }
                return cheque;
            } else {
                return cheque;
            }
        });
        state.chequesConComitentes = agregoComitente;
        state.comitenteNoValido = false;
    });

    builder.addCase("comitenteSubasta/guardarComitentesConcertada", (state, action) => {
        //state.comitentes = action.payload;
        const datosComitente = action.payload.response[0];
        // si no te posicionaste como mav ni sos staff comitente_comprador deberia estar vacio pq no podes ver datos de comitentes que no te pertenecen
        const agregoComitente = state.cheques.map(cheque => {
            cheque.comitente = datosComitente?.comitente_comprador;
            return cheque;
        });
        state.chequesConComitentes = agregoComitente;
        state.comitenteNoValido = false;
    });

    builder.addCase("comitenteSubasta/guardarComitentesNoOfertas", (state, action) => {
        const agregoComitente = state.cheques.map(cheque => {
            if(cheque.id === action.payload.row.id) {
                cheque.comitente = action.payload.response[0]
                return cheque;
            } else {
                return cheque;
            }
        });
        console.log(agregoComitente);
        state.chequesConComitentes = agregoComitente;
        state.comitenteNoValido = false;
    });

    builder.addCase("comitenteSubasta/guardarComitenteCondomino", (state, action) => {
        const agregoComitente = state.cheques.map(cheque => {
            if(cheque.id === action.payload.row.id) {
                cheque.comitente = action.payload.data
                return cheque;
            } else {
                return cheque;
            }
        });
        state.comitenteElegidoEnCondomino = action.payload.data;
        state.chequesConComitentes = agregoComitente;
        state.comitenteNoValido = false;
    });

    builder.addCase("comitenteSubasta/guardarComitenteCondominoModalOfertas", (state, action) => {
        const agregoComitente = state.cheques.map(cheque => {
            cheque.comitente = action.payload.data
            return cheque;
        });
        state.chequesConComitentes = agregoComitente;
        state.comitenteNoValido = false;
        state.comitenteElegidoEnCondomino = action.payload.data;
    });

    builder.addCase("comitenteSubasta/guardarComitentesArray", (state, action) => {
        state.comitentes = action.payload.response;
        const datosNuevos = state.cheques.map(cheque => {
            cheque.comitente = action.payload.response.find(x => x.cheque === cheque.id)?.comitente_comprador;
            return cheque
        });
        state.chequesConComitentes = datosNuevos;
        state.cheques = datosNuevos;
        state.comitenteNoValido = false;
        //console.log(current(state.cheques));
    });

    builder.addCase("comitenteSubasta/crearComitente", (state, action) => {
        state.crearComitente = action.payload.nro_comitente;
    });

    builder.addCase("comitenteSubasta/comitenteInvalido", (state, action) => {
        state.comitenteNoValido = action.payload;
        state.chequesConComitentes = [];
    });

    builder.addCase("comitenteSubasta/guardarCheques", (state, action) => {
        state.cheques = action.payload;
    });

    builder.addCase("comitenteSubasta/repetirComitente", (state, action) => {
        //state.comitentes.push(action.payload);
        state.chequesConComitentes = state.cheques.map(cheque => {
            cheque.comitente = Object.keys(state.comitenteElegidoEnCondomino).length > 0 ? state.comitenteElegidoEnCondomino : action.payload[0];
            return cheque;
        });
    });

    // builder.addCase("comitenteSubasta/reiniciarEstado", (state, action) => {
    //     state.chequesConComitentes = [];
    //     state.comitenteNoValido = false;
    // });

    builder.addCase("comitenteSubasta/reiniciarEstado", (state, action) => {
        return initialState;
    });

    builder.addDefaultCase((state, action) => state);
});

export default comitenteSubasta;

/*
switch (type) {
        case "comitenteSubasta/guardarComitentes": {
            return {
                ...state,
                comitentes: [
                    ...state.comitentes, payload.response
                ],
                chequesConComitentes: 
                    state.cheques.map(cheque => {
                        if(cheque.id !== payload.row.id) {
                            return cheque;
                        } else {
                            return {
                                ...cheque,
                                comitente: payload.response
                            }
                        }
                })
            }
        }

        case "comitenteSubasta/guardarComitentesArray": {
            return {
                ...state,
                comitentes: [
                    ...state.comitentes, ...payload.response
                ],
                chequesConComitentes: 
                    state.cheques.map(cheque => {
                        return {
                            ...cheque,
                            comitente: [payload.response.find(x => x.cheque === cheque.id).comitente_comprador]
                        }
                })
            }
        }

        case "comitenteSubasta/chequesDentroDeChequesConComitentes": {
            return {
                ...state,
                cheques: [
                    ...state.chequesConComitentes
                ]
            }
        }

        case "comitenteSubasta/crearComitente": {
            return {
                ...state,
                crearComitente: payload.nro_comitente

            }
        }

        case "comitenteSubasta/comitenteInvalido": {
            return {
                ...state,
                comitenteNoValido: payload
            }
        }

        case "comitenteSubasta/guardarCheques": {
            return {
                ...state,
                cheques: [
                    ...state.cheques,
                    ...payload
                ]
            }
        }

        case "comitenteSubasta/repetirComitente": {
            return {
                ...state,
                comitentes: [
                    ...state.comitentes, payload
                ],
                chequesConComitentes: 
                    state.cheques.map(cheque => {
                        return {
                            ...cheque,
                            comitente: payload
                        }
                })
                
            }
        }

        case "comitenteSubasta/reiniciarEstado": {
            return {
                ...state,
                crearComitente: 0,
                comitenteNoValido: false,
                repetirComitente: [],
                chequesConComitentes: []
                // return initialState

            }
        }
    
        default: return state;
    }
*/